// Import external modules and components
import React from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import NumberFormat from 'react-number-format'; 
import $ from 'jquery';

// Import custom modules, functions, variables and components
import { Steps, ScrollToTopOnMount, CheckoutForm, AdsHeader } from './Bases';

// Import custom styles
import './static/Checkout.scss';

// Import image files
import cybersticbent from './static/cyberstickbent.png';
import emailjs from "@emailjs/browser";
import { CYBERSTICK_BACKEND } from '.';



// Set delivery_date to 10 days time from today
const delivery_date = new Date(new Date().getTime()+(10*24*60*60*1000));
const PaymentMethod = {
    ONLINE: 'online',
    PAY_ON_DELIVERY: 'payondelivery'
  };
  
// Returns checkout page and controls its logic
export default class OrderDetails extends React.Component {
    constructor(props) {
        $('#spinner').removeClass('hidden');
        sessionStorage.removeItem('PIP'); // PIP stands for Payment In Progess
        super(props);
        this.state = {
            product_id: 1,
            amount: 1,
            initial_price: 55000,         // Initial price of one (always constant)
            price: 55000,                 // Price of one multiplied by the amount
            shipping: 2500,
            // shipping: 0,
            tax: '-',
            color: 'Black',
            total_price: 55000,           // state.price + shipping + tax
            delivery_date: delivery_date.toDateString(),
            askinfo: false,               // If true, customer's information is requested for

            currentPaymentMethod: PaymentMethod.ONLINE, // "online or payOnDelivery",

            checkout: false,
            backtrace: false,
            city_state: 'lekki',
        };
    }

    // Return cart amount to 1 and prices to initial_price (of 1)
    clearCart = () => {
        sessionStorage.clear();
        this.setState({amount: 1, price: this.state.initial_price, total_price: this.state.initial_price + this.state.shipping})
    }

    componentDidMount() {
        $('#spinner').addClass('hidden');
        $('body').removeClass('no-scroll');

        // Get product information from the backend and update the UI states of the component
        axios.get(`${CYBERSTICK_BACKEND}/cyberstick/products/${this.state.product_id}`)  
        .then((res) => {
            this.setState({initial_price: res['data']['sale_price']})
            this.setState({price: this.state.amount * res['data']['sale_price']})
            this.setState({total_price: (this.state.amount * res['data']['sale_price']) + this.state.shipping})
        })
        .catch((e) => {
            alert(e.message);
        })

        // Get amount stored in browser's session storage if there is
        if (sessionStorage.getItem('amount')) {
            const amount = Number(sessionStorage.getItem('amount'))
            this.setState((prevState) => (
                {'amount': amount, price: prevState.initial_price * amount, total_price: (prevState.initial_price * amount) + this.state.shipping}
            )) 
        } else {
            this.setState({'amount': 1})
        }
    }

    componentDidUpdate() {
        // As state amount gets changed, store the value in browser's session storage (in case of a page reload or similar case)    
        if (!this.state.askinfo) {
            sessionStorage.setItem('amount', this.state.amount)
            sessionStorage.setItem('shipping', this.state.shipping)
            sessionStorage.setItem('price', this.state.price)
            sessionStorage.setItem('total_price', this.state.total_price)
        }
    }    

    componentWillUnmount() {
        $('#spinner').removeClass('hidden');
    }    

    // Decrease amount of product in cart by 1 and subtract the price of 1 product from the total_price
    decreaseAmount = () => {
        if (this.state.amount > 1 && !this.state.askinfo)
            this.setState((prevState) => ({amount: prevState.amount - 1, price: prevState.price - prevState.initial_price,
            total_price: prevState.total_price - prevState.initial_price}))
    }    

    // Increase amount of product in cart by 1 and add the price of 1 product to the total_price
    increaseAmount = () => {
        if (!this.state.askinfo)    
            this.setState((prevState) => ({amount: prevState.amount + 1, price: prevState.price + prevState.initial_price,
                total_price: prevState.total_price + prevState.initial_price}))
    }

    submitInfo = () => {
        window.scrollTo(0, 0);

        // If payment on delivey, just send email
        let first_name = $('#first_name').val()
        let last_name = $('#last_name').val()
        let street_address = $('#street_address').val()
        let country = $('#country').val()
        let city_state = this.state.city_state
        let email = $('#email').val()
        let phone_number = $('#phone_number').val()
        let order_id = sessionStorage.getItem('order_token')
        let amount = this.state.amount

        let customer_name = `${first_name} ${last_name}`

        // alert(this.state.shipping)
        // alert(this.state.currentPaymentMethod)        
        // alert(PaymentMethod.PAY_ON_DELIVERY)

        // if (this.state.currentPaymentMethod == PaymentMethod.PAY_ON_DELIVERY && this.state.shipping != 1500 ) {
        //     window.scrollTo(0, 0);
        //     document.querySelector('#pay-error').classList.add('d-block')
        //     // alert(this.state.shipping)
        //     return
        // }

        $('#spinner').removeClass('hidden');
        document.querySelector('#pay-error').classList.remove('d-block')


        if (this.state.currentPaymentMethod == PaymentMethod.PAY_ON_DELIVERY || order_id == null) {
            emailjs.send('service_9d45rmc', 'template_axnpgry', {
                'to_name': customer_name,
                'reply_to': email,
            }, 'rmJsklIWliSqgNSbG')
            .then(res => {
                // alert('Order successful, Email confirmation sent')
                $('body').addClass('no-scroll');
                $('#success_overlay').removeClass('hidden');
                $('#spinner').addClass('hidden');
                window.scrollTo(0, 0);

                emailjs.send('service_9d45rmc', 'template_b1uxsif', {
                    'customer_name': customer_name,
                    'customer_address': street_address,
                    'customer_email': email,
                    'customer_number': phone_number,
                    'customer_state': city_state,
                    'amount': amount,
                    'price_paid': "payment on delivery",
                    'advert_mode': sessionStorage.getItem('advert_mode'),
                    'paystack_id': sessionStorage.getItem('order_token'),
                }, 'rmJsklIWliSqgNSbG')
                .then((res) => {
                    window.sessionStorage.clear()    
                    console.log('success')
                }).catch((res) => {console.log('failed')})
            }
            )
        }

        else {
            if (!sessionStorage.getItem('new_customer')) {
                $('#spinner').removeClass('hidden');
                axios.put(`${CYBERSTICK_BACKEND}/cyberstick/orders/${sessionStorage.getItem('order_token')}`, {
                    total_price: this.state.price + this.state.shipping,
                }).then((res) => {
                    axios.post(`${CYBERSTICK_BACKEND}/cyberstick/customers/`, {
                        first_name: first_name,
                        last_name: last_name,
                        street_address: street_address,
                        country: country,
                        city_state: city_state, 
                        email: email,
                        phone_number: phone_number,
                        order_id: order_id,
                    })
                    .then((res) => {
                        sessionStorage.setItem('customer', res['data']['first_name'] + ' ' + res['data']['last_name'])
                        sessionStorage.setItem('new_customer', res['data']['first_name'] + ' ' + res['data']['last_name'])
                        this.setState({checkout: true})
                    })
                    .catch((err) => {
                        $('#spinner').addClass('hidden'); 
                        alert(err.message); 
                        // sessionStorage.clear();
                        this.setState({backtrace: true})
                    })
                })
                .catch((err) => {
                    $('#spinner').addClass('hidden');
                    alert(err.message);
                    // alert('error')
                    // sessionStorage.clear()
                    this.setState({backtrace: true})
                })
            }
        }
    }   

    // Place an order by sending order information to the backend which stores the order with a status of 1 and returns the order's id
    placeOrder = () => {
        window.scrollTo(0, 0);
        document.querySelector('#pay-error').classList.remove('d-block')
        $('#spinner').removeClass('hidden');
        if (this.state.amount > 0 && !this.state.askinfo) {
        axios.post(`${CYBERSTICK_BACKEND}/cyberstick/orders/`, {
            id: this.state.product_id,
            amount: this.state.amount,
            total_price: this.state.total_price,
        })
        .then((res) => {
            sessionStorage.setItem('order_token', res['data']['Available']) // 'Available' variable stores the order id or token
            sessionStorage.setItem('amount', res['data']['amount'])
            sessionStorage.setItem('shipping', this.state.shipping)
            sessionStorage.removeItem('new_customer')
            sessionStorage.removeItem('customer')
            this.submitInfo()
        })
        .catch((e) => (alert(e.message)));
        }
    }

    
    // If this.state.askinfo is true, navigate to shipping else return markup for checkout page
    render() {    
        if (this.state.askinfo) {
            return <Navigate to='/shipping' state={{shipping: this.state.shipping,
                price: this.state.price,
                tax: this.state.tax, total_price: this.state.total_price}}
            />
        }
        else if (this.state.checkout)
        return <Navigate to='/payment' state={{shipping: this.state.shipping, price: this.state.price,
            tax: this.state.tax, total_price: this.state.total_price}}
        />
        return (
            <div>
                <ScrollToTopOnMount />
                <AdsHeader />

                <div className='container-fluid'>
                    
                <section className='row checkout-section-1
                justify-content-around align-items-center g-0px'>
                    <div className='col-md-6 col-12 aside-1 text-md-left text-center'>
                        <div class="alert alert-danger d-none" id="pay-error" role="alert">
                            pay on delivery available for only locations in Lagos
                        </div>    
                        <h3>Review Your Bag.</h3>
                        <div className='d-flex align-items-center checkout-order-info flex-sm-row flex-column
                        justify-content-start flex-wrap py-3'>
                            <img className='cyberstickbent' src={cybersticbent} alt='product'/>
                            <div className='checkout-p-wrapper'>
                                <p className='checkout-p my-1'>Cyberstick - {this.state.color}</p>
                                {/* <p className='checkout-p my-1'>Get it By {this.state.delivery_date}</p> */}
                            </div>
                            <div className='amount-wrapper d-flex align-items-center'>
                                <span className='amount px-3'>{this.state.amount}</span>
                                <div className='d-flex flex-column amount-controls'>
                                    <i className='bi-caret-up increase' onClick={this.increaseAmount}></i>
                                    <i className='bi-caret-down decrease' onClick={this.decreaseAmount}></i>
                                </div>
                            </div>
                            <div className='d-flex flex-column align-items-start
                            justify-content-center price-wrapper'>
                                <p className='checkout-p price my-1 rounded'><NumberFormat value={this.state.price}
                            displayType={'text'} thousandSeparator={true} prefix={'₦'} /></p>
                                <p className='checkout-p remove my-1' onClick={this.clearCart}>Remove</p>
                            </div>
                        </div>
                        <div className='text-white purple-color-gradient-bg note px-3 position-relative py-1 rounded top-30px'>Note</div>
                        <div className='position-relative top-50px' style={{color: 'rgba(255, 255, 255, .8)'}}>Delivery takes 2-3 working days for orders within Lagos and 2-6 working days for orders outside Lagos.</div>
                    </div>
                    <div className='col-md-4 col-12 aside-2 d-flex flex-column'>
                        <CheckoutForm header="order summary" price={this.state.price}
                        shipping={this.state.shipping} tax={this.state.tax} total_price={this.state.total_price}
                        />                   
                    </div>
                </section>
                </div>

                <div className="container-fluid my-5">
                <section className='row checkout-section-1 
                justify-content-start align-items-start g-0px text-sm-left text-center'>
                    <div className="col-md-6 col-12">
                        <div className="p-5 pb-30px">
                            <h3 className="fw-400">Shipping</h3> 
                            <p className="py-2 ">Where Should We Send Your Order?</p>
                        </div>    
                        <form className="form px-sm-5 px-1" onSubmit={(e) => {e.preventDefault()}}>
                            <p>Enter Your Name And Address:</p>    
                            <div className="form-group">    
                                <input id="first_name" type="text" className="form-control" placeholder="First Name" required/>    
                            </div>
                            <div className="form-group">    
                                <input id="last_name" type="text" className="form-control" placeholder="Last Name" required/>
                            </div>
                            <div className="form-group Autocomplete">
                                <input type="text" id="street_address" className="form-control" placeholder="Street Address" 
                                onChange={(e) => {
                                    sessionStorage.setItem('address', e.target.value)
                                }} required/>
                            </div>
                            <div className="row" id="street_extra">
                                {/* <div className="col form-group">
                                    <input type="text" id="city_state" className="form-control" placeholder="City/State" required/>
                                </div> */}
                                <div className="col form-group ">
                                    <select onChange={(e) => {
                                        let index = e.target.options.selectedIndex;
                                        let shipping = e.target.options[index].value
                                        let state = e.target.options[index].text
                                        this.setState({shipping: shipping, city_state: state});
                                        sessionStorage.setItem('shipping', shipping);
                                    }} class="form-select col form-select-lg pl-3" aria-label="" title="State" id="city_state">
                                        <option value="7000">Abule egba</option>
                                        <option value="7000">iyana ipaja</option>
                                        <option value="7000">Ikotun</option>
                                        <option value="7000">Igando</option>
                                        <option value="7000">Lasu</option>
                                        <option value="7000">Agege</option>
                                        <option value="7000">Berger</option>
                                        <option value="7000">Ketu</option>

                                        <option disabled></option>
                                        <option value="5000">Mushin</option>
                                        <option value="5000">Oshodi</option>
                                        <option value="5000">Yaba</option>
                                        <option value="5000">Surulere</option>
                                        <option value="5000">Illupeju</option>
                                        <option value="5000">Maryland</option>
                                        <option value="5000">Ikeja</option>

                                        <option disabled></option>
                                        <option value="3500">Iyanaworo</option>
                                        <option value="3500">Gbagada</option>
                                        <option value="3500">Bariga</option>

                                        <option disabled></option>
                                        <option value="5000">Sangotedo</option>
                                        <option value="5000">Abaraham</option>
                                        <option value="5000">Ogombo</option>
                                        <option value="5000">Ibeju lekki</option>

                                        <option value="3000">Osapa</option>
                                        <option value="3000">Agungi</option>
                                        <option value="3000">Jakande</option>
                                        <option value="3000">Ilassan</option>
                                        <option value="3000">Salem</option>

                                        <option disabled></option>
                                        <option value="3000">Osapa</option>
                                        <option value="3000">Agungi</option>
                                        <option value="3000">Jakande</option>
                                        <option value="3000">Ilassan</option>
                                        <option value="3000">Salem</option>

                                        <option disabled></option>
                                        <option value="4000">Ajah</option>
                                        <option value="2500">Victoria Islan(and its environs)</option>

                                        <option disabled></option>
                                        <option value="2500">Maruwa</option>
                                        <option value="2500" selected>Lekki</option>
                                        <option value="2500">Ikate</option>
                                        <option value="2500">Chisco</option>

                                        <option disabled></option>                                        
                                        <option value="3500">Ikota</option>
                                        <option value="3500">Oral</option>
                                        <option value="3500">Estate</option>
                                        <option value="3500">Eleganza</option>
                                        <option value="3500">VGC</option>
                                        <option value="3500">Chevron</option>
                                        <option value="3500">Orchid</option>
                                        <option value="3500">Igbo efon</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <input type="text" id="country" className="form-control" value='Nigeria' placeholder="Country" disabled required/>
                            </div>

                            <p className="mt-50px">What's Your Contact Information?</p>                        
                            <div className="form-group">
                                <input type="email" id="email" className="form-control"
                                placeholder="Email Address" required/>
                            </div>
                            <div className="form-group">
                                <input type="text" maxLength="15" id="phone_number" className="form-control"
                                placeholder="Phone Number" onChange={(e) => {
                                    sessionStorage.setItem('phone_number', e.target.value)
                                }} required/>
                            </div>
                            <select
                            class="form-select form-select-lg w-100 pl-3" aria-label="feedback"
                            onChange={(e) => {
                                let index = e.target.options.selectedIndex;
                                let advert_mode = e.target.options[index].value;
                                sessionStorage.setItem('advert_mode', advert_mode);}}>
                                <option value="none" className="text-muted">How Did You Hear About Us?</option>    
                                <option value="Internet Ads">Internet Ads </option>
                                <option value="Social Media">Social Media</option>
                                <option value="In Person Events">In Person Events</option>
                                <option value="Television">Television</option>
                                <option value="Flyers/Billboards">Flyers/Billboards</option>
                                <option value="Others">Other</option>
                            </select>
                            
                            <button onClick={() => {
                                this.setState({currentPaymentMethod: PaymentMethod.PAY_ON_DELIVERY}, () => {
                                    this.submitInfo();
                                })}} 
                                // this.setState({ currentPaymentMethod: PaymentMethod.PAY_ON_DELIVERY }, );
                                className='checkout-btn-outline d-none mt-20px' type='submit' value='Checkout'>
                                Pay on delivery (for Lagos only)
                            </button>
                            <button onClick={() => 
                                // {this.setState({currentPaymentMethod: PaymentMethod.ONLINE}); this.placeOrder()}}  
                                this.setState({currentPaymentMethod: PaymentMethod.ONLINE}, () => {
                                    this.placeOrder();
                                })}
                                className='checkout-btn  mt-30px' type='submit' value='Checkout'>
                                {/* Pay online */}
                                Proceed to payment 
                            </button>
                            
                        </form>
                    </div>    
                    
                </section>
                </div>
            </div>
            )
    }    
}
